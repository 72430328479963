import React from 'react'

import VideoSpot from '../assets/expedialawsuit-spot.webm'

import { Helmet } from 'react-helmet';

import { Link } from 'react-router-dom'

function Spot() {
  return (
    <>

    <Helmet>
      <title>Spot - Expedia Lawsuit</title>
    </Helmet>
    
      <div className="main">
        <div className="main-right">
            <h1><Link to="/">Expedia Lawsuit</Link></h1>
            <video src={VideoSpot} controls className="video-spot" />
            <h1 className="red">Act Now! Get the Compensation You Deserve! Make Expedia Pay for What They've Done!</h1>
            <Link to="/"><div className="button">CLICK HERE TO CONTACT US</div></Link>
            <p class="footer">EXPEDIA LAWSUIT &copy; 2022</p>
            <div className="disclaimer">* Logos used for identification purposes only. <br />** Use of trademark does not imply any relationship between Company, Travelocity or Expedia.</div>
        </div>
      </div>
    </>
  );
}

export default Spot;
