import Video from '../assets/video-2.webm'
import Poster from '../assets/poster.webp'

import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';

const Result =() => {
  return (
    <p>Thank you for your message! You will'be contacted as soon as possible. :)</p>
  )
}


function Home() {

  const [result, showResult] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_26977mp', 'template_eid7wzd', form.current, 'user_7biBNECIYJtH8raywhSYR')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
      showResult(true);
  };

  return (
    <>

    <Helmet>
      <title>Expedia Lawsuit</title>
    </Helmet>
    
      <div className="main">
        <div className="main-left">
          <h1>EXPEDIA LAWSUIT</h1>
            <video src={Video} poster={Poster} muted loop autoPlay className="video" />
        </div>
        <div className="main-right">
          <h1>We're currently down for maintenance. We will be back as soon as possible.</h1>
          {/* <h1>Contact form</h1>
          <p>From 2020 into 2021 travelers around the world experienced travel hardship due to Covid 19. Unfortunately due to alleged malicious intention by Expedia and Travelocity many of these same travelers ended up stranded or without compensation for  cancelled flights.
          </p>

          <p>Expedia made it a practice to provide intentionally vague or incorrect information to clients so that they could not use travel vouchers or claim travel credits. They profited while customers suffered, often far away from their homes.
          </p>

          <p>A class action law suit being filed in US Federal Court seeks to recover damages victims of Expedia and Travelocity Inc. that whom purchased tickets during this period experienced. Customers were promised travel credits or vouchers but did not receive ones that could be actually used.  The claim seeks reimbursement and damages for the deception, fraud and hardship caused and perpetrated by the Defendant in this suit.</p>

          <p>If you believe you have suffered in a like manner at the hands of Expedia and Travelocity Inc. then contact us today to see how you can join a class action or sole suit and get the compensation you deserve due to the mismanagement and likely ill intent of Expedia and Travelocity Inc.</p>
          <p className="red">Act Now! Get the Compensation You Deserve! Make Expedia Pay for What They've Done!</p>

          <form ref={form} onSubmit={sendEmail}>
            <input type="text" name="user_name" minlength="5" placeholder="Add your name..." required className="user-name" />
            <input type="email" name="user_email" placeholder="Add your email..." required className="user-email" />
            <input type="tel" name="user_phone" minlength="5" placeholder="Add your phone number..." required className="user-phone" />
            <input name="message"placeholder="Add details..." className="user-details" />
            <input className="last-item" type="submit" value="Send!" required />
          </form>
                
            { result ? <Result /> : null }

            <p class="footer">EXPEDIA LAWSUIT &copy; 2022</p>
          <div className="disclaimer">* Logos used for identification purposes only. <br />** Use of trademark does not imply any relationship between Company, Travelocity or Expedia.</div> */}
        </div>
      </div>
    </>
  );
}

export default Home;
